import { baseRemSize } from '@/plugins/Const';
import AppUtil from '@/plugins/AppUtil';
import EventBus from '@/plugins/EventBus'
import Constants from '@/plugins/Const.js';
import defaultImage from '@/assets/commonImage/noimg.svg'

export default {
    components: {
        BtnCompo: () => import('@/components/btnCompo.vue'),
        Header: () => import('@/components/headerCompo.vue'),
        rightSideCompo: () => import('@/components/rightSideCompo.vue'),
        paging: () => import("@/components/pagingCompo.vue"),
        Loading: () => import("@/components/loadingCompo.vue"),
        DefaultPopup: () => import('@/popup/defaultPopup.vue'),
        xrayCompo: () => import('@/components/xrayCompo.vue'),
        spineRsltCompo: () => import('@/components/spineRsltCompo.vue'),
        risserRsltCompo: () => import('@/components/risserRsltCompo.vue'),
        totRsltCompo: () => import('@/components/totRsltCompo.vue'),
        Input: () => import('@/components/inputCompo.vue'),
        patientSelectCompo: () => import('@/components/patientSelectCompo.vue'),
        UserListCompo: () => import('@/components/userListCompo.vue')
    },
    directives: {
    },
    props: {},
    data() {
        return {
            window: {
                width: 0,
                height: 0
            },
            mobile: 375,
            tablet: 600,
            laptop: 1030,
            desktop: 1904,
            list:[],
            READING_SPINE_NAME: Constants.READING_SPINE_NAME,
            READING_HANDS_NAME: Constants.READING_HANDS_NAME,
            READING_RISSER_NAME: Constants.READING_RISSER_NAME,
            XRAY_TYPE_SPINE: Constants.XRAY_TYPE_SPINE,
            XRAY_TYPE_HANDS: Constants.XRAY_TYPE_HANDS,
            XRAY_TYPE_BOTH: Constants.XRAY_TYPE_BOTH,
            CONST_OK: Constants.OK,
            CONST_CANCEL: Constants.CANCEL,
            CONST_REG: Constants.REG,
            CONST_MOD: Constants.MOD,
            CONST_DELETE: Constants.DELETE,
            CONST_LIST: Constants.LIST,
            CONST_CLOSE: Constants.CLOSE,
            CONST_CONFM: Constants.CONFM,
            CONST_RETURN: Constants.RETURN,
            defaultImage: defaultImage,
            SPINE_APEX: Constants.SPINE_APEX,
            SPINE_DIRECTION: Constants.SPINE_DIRECTION,
            STATE_REQST:Constants.STATE_REQST,
            STATE_CONFM: Constants.STATE_CONFM,
            STATE_CANCL: Constants.STATE_CANCL,
            STATE_RETURN: Constants.STATE_RETURN
        };
    },
    computed: {
		isIE() {
            return AppUtil.isIE()
        },
        userInfo() {
            return this.$store.getters['auth/userInfo']
        },
        isLogin() {
            return this.$store.getters['auth/isLogin'];
        },
        userRole(){
            return this.$store.getters['auth/userRole'];
        }
	},
    watch: {},
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted() {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
   
    methods: {
        numberWithCommas: AppUtil.numberWithCommas,
        replaceLnToBr: AppUtil.replaceLnToBr,
        getUrlForm: AppUtil.getUrlForm,
        eventOn: AppUtil.eventOn,
        eventOff: AppUtil.eventOff,
        eventEmit: AppUtil.eventEmit,
        sleep: AppUtil.sleep,

        getText(v){
            let el = new DOMParser().parseFromString(v, "text/html");
            for (let i = 0; i < el.getElementsByTagName("p").length; i++) {
                if (el.getElementsByTagName("p")[i].innerText.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, "") !='') {
                    return el.getElementsByTagName("p")[i].innerText
                }
            }
        },
        newLineToBr(text){
            // \n -> <br/>
            let chgText = text.replace(/(?:\r\n|\r|\n)/g, '<br />')
            chgText.split('\n').join('<br />');
            return chgText
        },
        pxToRem(pxSize) {
            let retRem = `${(parseInt(pxSize) / baseRemSize)}rem`;
            return retRem;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        

        /**
         * 페이지 전환에 사용할 공통 함수
         * 
         * @param {*} path /로 시작할 경우 해당 페이지로 /없이 시작할 경우 common 페이지로 라우팅함
         * @param {*} query 페이지 이동시 같이 전달할 쿼리
        */

       

        /**
         * 페이지 전환에 사용할 공통 함수
         * 
         * @param {*} name 페이지명으로 공통 화면의 경우는 앞에 생략하고 페이지명 입력하면됨
         * @param {*} query 페이지 이동시 같이 전달할 쿼리
         * @param {*} params 페이지 이동시 같이 전달할 파라미터
        */


        onShowPopupAction(popup, callback = null) {
            popup.route = this.$route.fullPath
            this.$store.dispatch("popup/addPopup", popup);
            window.thisVue.$nextTick(() => {
                popup.isShow = true;
                popup.isCreate = true;
                popup.dimClose = true;
                if (callback != null) {
                    callback();
                }
            });
        },

        onClosePopupAction(popup, callback = null) {
            this.$store.dispatch("popup/removePopup", popup);
            popup.route = null
            popup.isShow = false;
            popup.dimClose = false;
            if (callback != null) {
                callback();
            }
        },

        showPopup(msg, status) {
            if (msg) {
                EventBus.emit('SHOW_POPUP', msg, status)
            }
        },
        displayedAt(createdAt) {
            const milliSeconds = new Date() - new Date(createdAt)
            const seconds = milliSeconds / 1000
            if (seconds < 60) return `방금 전`
            const minutes = seconds / 60
            if (minutes < 60) return `${Math.floor(minutes)}분 전`
            const hours = minutes / 60
            if (hours < 24) return `${Math.floor(hours)}시간 전`
            const days = hours / 24
            if (days < 7) return `${Math.floor(days)}일 전`
            const weeks = days / 7
            if (weeks < 5) return `${Math.floor(weeks)}주 전`
            const months = days / 30
            if (months < 12) return `${Math.floor(months)}달 전`
            const years = days / 365
            return `${Math.floor(years)}년 전`
        },
        replaceByDefault(e, imgUrl){
            e.target.src = imgUrl;
        },
        phoneFomatter(num, type) {
            var formatNum = '';
            try {
                if (num.length == 11) {
                    if (type == 0) {
                        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
                    } else {
                        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
                    }
                } else if (num.length == 8) {
                    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
                } else {
                    if (num.indexOf('02') == 0) {
                        if (type == 0) {
                            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
                        } else {
                            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
                        }
                    } else {
                        if (type == 0) {
                            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
                        } else {
                            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                        }
                    }
                }
            } catch (error) {
                error
            }
            return formatNum;
        },
        validateEmail(email) {
            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            return re.test(email);
        },
        validatePhone(phone) {
            var args = phone.replace(/-/gi, "")
            if (/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(args)) {
                return true;
            }
            return false;
        },
        showToast(text){
            this.$toasted.show(text,{duration: 2000, position: 'bottom-center'});
        },
        utf8_to_b64( str ) {
            return window.btoa(encodeURIComponent( str ));
        },
        b64_to_utf8( str ) {
            return decodeURIComponent(window.atob( str ));
        },
        showDetail(img) {
            window.open('/imageView/'+this.utf8_to_b64(img), '_blank');    
        },
        onErrorImage(event) {
            event.target.src = defaultImage;
            event.target.style = '-o-object-fit: scale-down; object-fit:scale-down;'
        },
        getToDay() {
            return new Date().toISOString().slice(0, 10);
        },
        roundAngles(angles) {
            if (angles) {
                if (angles.firstAngle && typeof angles.firstAngle !== 'string') {
                    angles.firstAngle = angles.firstAngle.toFixed(1);
                }
                if (angles.secondAngle && typeof angles.secondAngle !== 'string') {
                    angles.secondAngle = angles.secondAngle.toFixed(1);
                }
                if (angles.thirdAngle && typeof angles.thirdAngle !== 'string') {
                    angles.thirdAngle = angles.thirdAngle.toFixed(1);
                }
            }
        },
        initLocalStorage(){
            const xrayMap = localStorage.getItem("xrayMap");
            if(xrayMap) localStorage.removeItem("xrayMap");
    
            const targetInfo = localStorage.getItem("targetInfo");
            if(targetInfo) {
                localStorage.removeItem("targetInfo");
                EventBus.emit('change_target');
            }
            
            const rsltMap = localStorage.getItem("rsltMap");
            if(rsltMap) localStorage.removeItem("rsltMap");

            const patientInfo = localStorage.getItem("patientInfo");
            if(patientInfo) localStorage.removeItem("patientInfo");

            const pictureDt = localStorage.getItem("pictureDt");
            if(pictureDt) localStorage.removeItem("pictureDt");

            const activeUserCnt = localStorage.getItem("activeUserCnt");
            if(activeUserCnt) localStorage.removeItem("activeUserCnt");
        },
        initSearch(){
            this.$store.dispatch("page/setSearchType", '');
            this.$store.dispatch("page/setSearchQuery", '');
        },
        calculatedAgeAtFirstAnalysis(birth, pictureDtStr) {
            if (birth && pictureDtStr) {
                const pictureDate = new Date(pictureDtStr);
                const birthDate = new Date(birth);
                let years = pictureDate.getFullYear() - birthDate.getFullYear();
                let months = pictureDate.getMonth() - birthDate.getMonth();
                if (months < 0) {
                    years--;
                    months += 12;
                } else if (months === 0 && pictureDate.getDate() < birthDate.getDate()) {
                    years--;
                    months = 11;
                }
                return `${years}세 ${months}개월`;
            }
            return '';
        }
    },
};